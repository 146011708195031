<template>
  <div v-if="pageResponse">
    <LibInactiveLocaleMessage :is-locale-active="isLocaleActive" />
    <Breadcrumb v-if="showBreadcrumb" :color-mode="pageTheme" :slug="slug" />
    <MappedComponent :item="pageResponse.page" />
  </div>
</template>

<script setup lang="ts">
import type { SearchAction } from 'schema-dts';
import Breadcrumb from '~/components/lib/Breadcrumb.vue';
import { useContentfulPage } from '~~/composables/useContentfulPage';
import { pageToDefaultThemeMap } from '~/utils/pageToDefaultThemeMap';
import { buildUrlString } from '~/utils/buildUrlString';

const locale = useRouteParamLocale();
const slug = useSlug();
const { $colorMode, $globalPageSettings, $localeSlugMap } = useNuxtApp();

const pageResponse = await useContentfulPage({
  slug: slug.value,
  locale: locale.value
});

// search action should only be added to homepage
// @see https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
if (
  slug.value === '' &&
  $globalPageSettings.value?.searchResultsPage?.metadata?.slug
) {
  useJsonld({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: buildUrlString(locale.value, '', undefined, undefined, true),
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: buildUrlString(
          locale.value,
          $globalPageSettings.value?.searchResultsPage.metadata.slug,
          'q={search_term_string}',
          undefined,
          true
        )
      },
      'query-input': 'required name=search_term_string'
    } as SearchAction
    // "query-input" is not defined in schema but necessary
    // @see https://stackoverflow.com/questions/27140153/error-page-contains-property-query-input-which-is-not-part-of-the-schema
  });
}

const companyName = $globalPageSettings.value?.companyName ?? '';
useMetadata(pageResponse.value.page, companyName);

const isLocaleActive = computed(
  () =>
    pageResponse.value.page.fields?.locales?.some(
      (enabledLocale) => enabledLocale.toLowerCase() === locale.value
    ) ?? true
);

const pageTheme = computed(
  () =>
    pageResponse.value.page.fields.metadata?.fields?.pageTheme ??
    pageToDefaultThemeMap[pageResponse.value.page.sys.contentType.sys.id]
);

const showBreadcrumb = computed(
  () => pageResponse.value.page.fields.metadata?.fields?.showBreadcrumb ?? true
);

watch(
  pageTheme,
  () => {
    $colorMode.value = pageTheme.value;
  },
  { immediate: true }
);

watch(
  () => pageResponse.value.localeSlugMap,
  () => {
    $localeSlugMap.value = pageResponse.value.localeSlugMap;
  },
  { immediate: true }
);
</script>
